<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Cobro - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
               <b-row>

                  <b-col md="6">
                    <b-form-group label="Cliente :">
                    <b-form-input disabled type="text" :value="charge.client"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Caja :">
                    <b-form-input disabled type="text" :value="charge.box"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Fecha :">
                    <b-form-input class="text-center" disabled type="text" :value="charge.broadcast_date"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Documento :">
                      <b-form-input disabled v-model="charge.document"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Medio de Pago :">
                      <b-form-select disabled v-model="charge.payment_method" :options="payment_method"></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Moneda :">
                      <b-form-select disabled v-model="charge.coin" :options="coin"></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col md="5">
                    <b-form-group label="Observación :">
                      <b-form-input disabled v-model="charge.observation"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Total :">
                      <b-form-input class="text-right" disabled v-model="charge.total"></b-form-input>
                    </b-form-group>
                  </b-col>

                </b-row>
                

              <b-row  class="justify-content-md-center mt-3">
                <b-col md="3">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/cobro/listar' }" append >REGRESAR</b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal Products -->
    <ModalProducts />
    <!-- Modal Products -->
    <LoadingComponent :is-visible="isLoading"/>

    
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import EventBus from '@/assets/js/EventBus';
import converter from "@/assets/js/NumberToLetters";
import { mapState,mapActions } from "vuex";


// components
import ModalProducts from './components/ModalProduct'
import cInputDetail from './components/InputDetail'
import LoadingComponent from './../pages/Loading'
export default {
  name: "InputEdit",
  props: ["id_charge"],
  components:{
      ModalProducts,
      cInputDetail,
      vSelect,
      LoadingComponent
  },
  data() {
    return {
      isLoading:false,
      module: 'Charge',
      role: 5,
      charge: {
        id_charge:'',
        id_client:'',
        id_user:'',
        id_box:'',
        module:'',
        id_module:'',
        client:'',
        box:'',
        broadcast_date:'',
        payment_method:'',
        document:'',
        number_op:'',
        coin:'',
        exchange_rate:'',
        bank:'',
        observation:'',
        total:'',
        state:'1',
      },
      payment_method: [
          {value :"001", text :'DEPÓSITO EN CUENTA'},
          {value :"003", text :'TRANSFERENCIA DE FONDOS'},
          {value :"004", text :'ORDEN DE PAGO'},
          {value :"005", text :'TARJETA DE DÉBITO'},
          {value :"006", text :'TARJETA DE CRÉDITO'},
          {value :"007", text :'CHEQUES CON LA CLÁUSULA DE "NO NEGOCIABLE"'},
          {value :"008", text :'EFECTIVO'},
          {value :"009", text :'EFECTIVO, EN LOS DEMÁS CASOS'},
          {value :"101", text :'TRANSFERENCIAS - COMERCIO EXTERIOR'},
          {value :"102", text :'CHEQUES BANCARIOS  - COMERCIO EXTERIOR'},
          {value :"999", text :'CRÉDITO'},
      ],
      coin:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
        {value: "CLP", text : "Pesos Chilenos"},
      ],
 
      //erors
      errors: {
        id_provider: false,
        id_warehouse: false,
        serie:false,
        number:false,
        broadcast_date:false,
        arrival_date:false,
        input_detail:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewInput();
  },
  methods: {
    ViewInput,
  },

  computed: {
   
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    }
  },
};

function ViewInput() {
  let id_charge = je.decrypt(this.id_charge);
  let me = this;
  let url = this.url_base+"charge/view/"+id_charge;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.charge.id_charge = response.data.result.id_charge;
        me.charge.id_client = response.data.result.id_client;
        me.charge.id_user = response.data.result.id_user;
        me.charge.id_box = response.data.result.id_box;
        me.charge.module = response.data.result.module;
        me.charge.id_module = response.data.result.id_module;
        me.charge.client = response.data.result.client;
        me.charge.broadcast_date = response.data.result.broadcast_date;
        me.charge.payment_method = response.data.result.payment_method;
        me.charge.document = response.data.result.document;
        me.charge.number_op = response.data.result.number_op;
        me.charge.coin = response.data.result.coin;
        me.charge.exchange_rate = response.data.result.exchange_rate;
        me.charge.bank = response.data.result.bank;
        me.charge.observation = response.data.result.observation;
        me.charge.total = response.data.result.total;
        me.charge.state = response.data.result.state;

        me.charge.client = response.data.result.client_name+" - "+response.data.result.client_document_number;
        me.charge.box = response.data.result.box_code+" - "+response.data.result.user;
    
      } else {
        Swal.fire({ icon: 'error', text:response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}



</script>
